module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"John Gibbons - Conductor | Composer | Pianist | Arranger | Organist","short_name":"John Gibbons - Musician","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/jgthumb.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ea0c45c703d6328c785deea6d49dd64"},
    },{
      plugin: require('../plugins/gatsby-plugin-cloudinary/gatsby-browser.js'),
      options: {"plugins":[],"cloudName":"finalarena","apiKey":"998155615852327","apiSecret":"hEecr8DqEi2lJQyCic4bERCK_-g","rootFolder":"johngibbonsmusic.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":430,"wrapperStyle":"z-index: 1","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","mediaFolder":"/opt/build/repo/content/assets"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
