// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gallery-folder-js": () => import("./../../../src/templates/gallery-folder.js" /* webpackChunkName: "component---src-templates-gallery-folder-js" */),
  "component---src-templates-pages-about-js": () => import("./../../../src/templates/pages/about.js" /* webpackChunkName: "component---src-templates-pages-about-js" */),
  "component---src-templates-pages-blog-js": () => import("./../../../src/templates/pages/blog.js" /* webpackChunkName: "component---src-templates-pages-blog-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-dynamic-page-js": () => import("./../../../src/templates/pages/dynamic-page.js" /* webpackChunkName: "component---src-templates-pages-dynamic-page-js" */),
  "component---src-templates-pages-events-js": () => import("./../../../src/templates/pages/events.js" /* webpackChunkName: "component---src-templates-pages-events-js" */),
  "component---src-templates-pages-galleries-js": () => import("./../../../src/templates/pages/galleries.js" /* webpackChunkName: "component---src-templates-pages-galleries-js" */),
  "component---src-templates-pages-testimonials-js": () => import("./../../../src/templates/pages/testimonials.js" /* webpackChunkName: "component---src-templates-pages-testimonials-js" */)
}

